<template>
  <div
    class="mainBody"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#464c5c"
  ></div>
</template>

<script>
export default {
  name: "loginPage",
  data() {
    return {
      loading: true,
      loadingText: "正在获取平台授权",
    };
  },
  mounted() {
    this.TXCampusLogin();
  },

  methods: {
    // 通过腾讯智慧校园鉴权登录的逻辑
    TXCampusLogin() {
      const params = location.search;
      if (params == "") {
        this.$notify.error({
          title: "登录平台错误",
          message: "请通过腾讯智慧校园登录后再试",
        });
        this.loadingText = "请通过腾讯智慧校园登录后再试";
      } else {
        // 拆分params获取code（#后面的内容没被过滤） 发送给后端接口 获取登录回调，如果登录成功 跳转内页 如果失败什么都不做
        let code = this.getParam("code");
        let appid = this.getParam("appid");

        if (code) {
          this.loginViaTX(code, appid);
          this.loadingText = "登录中";
        } else {
          this.$notify.error({
            title: "腾讯智慧校园错误",
            message: "无法获取登录code，请联系腾讯智慧校园管理员！",
          });
          this.loadingText = "无法获取授权";
        }
      }
    },

    loginViaTX(code, appid) {
      // 发送code给后端胖子，获取单点登录回调状态，200为登录成功，其他为登录失败
      this.axios
        .post("/api/login/tencentCampus", {
          code,
          appid,
        }) /* 把表单的数据以json形式post给后端 得到的是一个promise对象，用then调用成功的回调。*/
        .then((res) => {
          if (res.data.code === 200) {
            this.$store.commit("SET_TOKEN", res.data.token);
            this.$store.commit("SET_USERNAME", res.data.data.userName);
            this.$store.commit("SET_AVATAR", res.data.data.userAvatarUrl);
            this.$store.commit("SET_GROUPID", res.data.data.groupid);

            /* 使用elementui的$message插件显示成功图标 */
            this.$message({
              message: "登陆成功",
              type: "success",
            });

            this.loadingText = "登录成功";

            this.$router.push({ path: "/homePage" });
          }
        })
        .catch((err) => {
          if (err.response.data.code === 4003) {
            this.$message({
              message: "没有权限登录",
              type: "warning",
            });
            this.loadingText = "没有权限登录";
          } else {
            this.$message({
              message: "登录错误" + err.response.data.message,
              type: "warning",
            });
            this.loadingText = "登录错误";
          }
        });
    },

    //从地址栏过滤code参数
    getParam(key) {
      //假设地址栏url为http://www.xxx.com/test?name=zhangshan&age=50#hello;
      let url = location.href;
      let params = this.getParamByUrl(url);
      let data = params[key];
      let value = data.split("#")[0];
      return value;

      // let pattern = new RegExp("[?&]*" + key + "=([^&]+)");

      // try {
      //   let data = url.match(pattern)[1];
      //   let value = data.substring(0,data.indexOf("#"))
      //   console.log("code:" + value)
      //   return value;
      // } catch (err) {
      //   return undefined;
      // }
    },

    getParamByUrl(url) {
      let theRequest = new Object();
      let index = url.indexOf("?");
      if (index != -1) {
        let str = url.substr(index + 1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.mainBody {
  background: $backgroundColor;
  height: 100vh;
  display: flex; /* 设置为flex布局  让子元素的内容水平垂直居中 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>
