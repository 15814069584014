import Vue from 'vue'
import Vuex from 'vuex'
import cookie from '@/assets/js/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: cookie.getCookie('username') || "未知用户",
    userAvatarUrl: cookie.getCookie('avatar'),
    token: cookie.getCookie('token'),
    groupid: cookie.getCookie('groupid'),
    signInState: false
  },
  mutations: {

    SET_USERNAME(state, data) {
      state.userName = data
      cookie.setCookie('username', data)
    },

    SET_AVATAR(state, data) {
      state.userAvatarUrl = data
      cookie.setCookie('avatar', data)
    },

    SET_TOKEN(state, data) {
      state.token = data
      cookie.setCookie('token', data)
      state.signInState = true
    },

    SET_GROUP(state, data) {
      state.groupid = data
      cookie.setCookie('groupid', data)
    },

    LOGOUT: (state) => {
      // 登出的时候要清除token等数据
      state.userName = ""
      state.userAvatarUrl = ""
      state.signInState = false
      state.groupid = ""
      cookie.logout()
    }
  },
  actions: {
  },
  modules: {
  }
})
