<template>
  <!-- 菜单的组件，通过传入菜单名字达到切换的目的 -->

  <div class="main">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      @select="handleSelect"
      background-color="#fff"
      text-color="#6E7D8F"
      active-text-color="#0cb181"
    >
      <el-menu-item
        v-for="item in menuNameList"
        :key="item.index"
        :index="item.index"
        :disabled="item.disabled"
        >{{ item.title }}</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "childMenu",
  props: {
    menuNameList: Array,
  },

  mounted() {
    this.$emit("selectMenu", this.menuNameList[0].index);
  },

  data() {
    return {
      activeIndex: this.menuNameList[0].index,
    };
  },

  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      console.log(key);
      this.$emit("selectMenu", key);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.main {
  height: 56px;
  line-height: 56px;
}

.main ul {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .el-menu-item {
    border-bottom: 2px solid $mainColor;
  }
}
</style>