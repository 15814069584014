<template>
  <div class="main">
    <!-- 按钮组 -->
    <div class="main-btn">
      <el-button
        size="medium"
        type="primary"
        class="createBtn"
        @click="createNew()"
        >新建</el-button
      >

      <div class="input-search">
        <el-input
          style="width: 200px"
          v-model="searchName"
          placeholder="请输入活动类型名字"
        ></el-input>

        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="doSeatch()"
        ></i>
      </div>
    </div>

    <!-- 表格部分 -->
    <div class="main-form-div">
      <el-table
        border
        v-loading="loading"
        :data="
          this.list.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" width="55" label="序号"></el-table-column>
        <el-table-column prop="activityName" label="活动名称"></el-table-column>
        <el-table-column prop="typeName" label="活动类型"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
        <el-table-column prop="timeStamp" label="创建时间"></el-table-column>
        <el-table-column prop="creator" label="创建者"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click.prevent="editRow(scope.$index, scope.row, list)"
              type="text"
              size="small"
              >编辑</el-button
            >

            <el-button
              @click.prevent="manageRow(scope.$index, scope.row, list)"
              type="text"
              size="small"
              >管理</el-button
            >

            <el-button
              @click.prevent="deleteRow(scope.$index, scope.row, list)"
              type="text"
              size="small"
              style="color: red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin: 20px"
        background
        layout="total, prev, pager, next, sizes"
        @current-change="handleCurrentChange"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <!-- 模态弹出框部分 -->
    <!-- 新建/编辑窗口 -->
    <el-dialog :title="dialogTitle" :visible.sync="showEditWindow" width="40%">
      <el-form ref="formData" label-width="150px" class="dialogWindow">
        <div v-if="dialogTitle == '新建'">
          <el-form-item label="活动名称">
            <el-input
              v-model="formData.activityName"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动类型">
            <el-select v-model="formData.typeId" placeholder="请选择">
              <el-option
                v-for="item in activityTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动开始时间">
            <el-date-picker
              v-model="formData.startTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动结束时间">
            <el-date-picker
              v-model="formData.endTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报名开始时间">
            <el-date-picker
              v-model="formData.signUpStartTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报名结束时间">
            <el-date-picker
              v-model="formData.signUpEndTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="介绍">
            <el-input
              type="textarea"
              :rows="2"
              v-model="formData.introduction"
            ></el-input>
          </el-form-item>
          <el-form-item label="可见范围">
            <el-cascader
              v-model="formData.classesArea"
              placeholder="请选择"
              :options="classOption"
              :props="{ multiple: true }"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
        </div>

        <div v-if="dialogTitle == '编辑'">
          <el-form-item label="活动名称">
            {{ formData.activityName }}
          </el-form-item>
          <el-form-item label="活动类型"> {{ selectTypeName }} </el-form-item>
          <el-form-item label="活动开始时间">
            {{ formData.startTime }}

            <!-- <el-date-picker
              v-model="formData.startTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="活动结束时间">
            {{ formData.endTime }}
            <!-- <el-date-picker
              v-model="formData.endTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="报名开始时间">
            {{ formData.signUpStartTime }}

            <!-- <el-date-picker
              v-model="formData.signUpStartTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="报名结束时间">
            {{ formData.signUpEndTime }}
            <!-- <el-date-picker
              v-model="formData.signUpEndTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="介绍">
            <el-input
              type="textarea"
              :rows="2"
              v-model="formData.introduction"
            ></el-input>
          </el-form-item>
          <el-form-item label="可见范围">
            <el-cascader
              v-model="formData.classesArea"
              placeholder="请选择"
              :options="classOption"
              :props="{ multiple: true }"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditWindow = false">取 消</el-button>
        <el-button type="primary" @click="saveData()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "activityForm",
  data() {
    return {
      searchName: "",
      loading: false,
      currentPage: 1 /* 当前页页码 */,
      pageSize: 10 /* 每页显示的条数 */,
      list: [],
      //   新建表单属性
      formData: {
        activityId: "",
        activityName: "",
        typeId: "",
        startTime: "",
        endTime: "",
        signUpStartTime: "",
        signUpEndTime: "",
        introduction: "",
        classesArea: [],
      },
      selectTypeName: "",

      //   弹出框属性
      showEditWindow: false,
      dialogTitle: "新建",

      //选项的属性
      activityTypeOption: [
        // {
        //   value: "0001",
        //   label: "三方会谈",
        // },
      ],
      classOption: [
        // {
        //   value: "0001",
        //   label: "小学部",
        //   children: [
        //     {
        //       value: "00011",
        //       label: "1A",
        //     },
        //   ],
        // },
        // {
        //   value: "0002",
        //   label: "校务办",
        //   children: [
        //     {
        //       value: "00011",
        //       label: "ICT部门",
        //     },
        //   ],
        // },
      ],
    };
  },

  mounted() {
    //请求活动类型选项
    this.getActivityType();
    //请求班级活动列表
    this.getClassList();
    //请求数据
    this.getList();
  },

  methods: {
    // 页码器的函数
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },

    //弹出窗逻辑
    //创建按钮
    createNew() {
      this.showEditWindow = true;
      this.dialogTitle = "新建";

      this.formData.activityId = "";
      this.formData.activityName = "";
      this.formData.typeId = "";
      this.formData.startTime = "";
      this.formData.endTime = "";
      this.formData.signUpStartTime = "";
      this.formData.signUpEndTime = "";
      this.formData.introduction = "";
      this.formData.classesArea = [];
    },

    //编辑按钮
    editRow(index, row) {
      this.showEditWindow = true;
      this.dialogTitle = "编辑";

      this.formData.activityId = row.activityId;
      this.formData.activityName = row.activityName;
      this.formData.typeId = row.typeId;
      this.formData.startTime = row.startTime;
      this.formData.endTime = row.endTime;
      this.formData.signUpStartTime = row.signUpStartTime;
      this.formData.signUpEndTime = row.signUpEndTime;
      this.formData.introduction = row.introduction;
      this.formData.classesArea = row.classesArea;
      this.selectTypeName = row.typeName;
    },

    //请求活动类型选项
    getActivityType() {
      this.axios
        .get("/api/common_activity_type_list/search", {
          params: {},
          headers: { token: this.$store.state.token },
        })
        .then((res) => {
          this.activityTypeOption = res.data.data;
        });
    },

    //请求班级活动列表
    getClassList() {
      this.axios
        .get("/api/common_class_list/search", {
          params: {},
          headers: { token: this.$store.state.token },
        })
        .then((res) => {
          this.classOption = res.data.data;
        });
    },

    // 删除逻辑
    deleteRow(index, row, table) {
      this.$prompt("请输入Delete删除这条信息", "删除确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (value === "Delete") {
          this.$confirm(
            "确认删除该信息吗?删除后该信息后的所有记录将被清除！",
            "删除确认",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              let deleteData = [];
              deleteData.push(row.activityId);

              //请求删除的接口
              this.axios
                .post(
                  "/api/web_activity_list/delete",
                  {
                    ids: deleteData,
                  },
                  { headers: { token: this.$store.state.token } }
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });

                    if (
                      table[1 + (this.currentPage - 1) * this.pageSize] == null
                    ) {
                      //如果当前页是最后一页，并且倒数第二项是空的，说明删除的是最后一页的最后一项
                      table.splice(
                        index + (this.currentPage - 1) * this.pageSize,
                        1
                      );
                      this.setCurrentPage();
                    } else {
                      table.splice(
                        index + (this.currentPage - 1) * this.pageSize,
                        1
                      );
                    }
                  } else {
                    this.$message.error("数据删除失败：" + res.data.message);
                  }
                })
                .catch((err) => {
                  this.$message.error("服务错误：" + err.response.status);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "info",
            message: "输入错误，取消删除操作！",
          });
        }
      });
    },

    setCurrentPage() {
      this.currentPage = this.currentPage - 1;
      console.log(this.currentPage);
    },

    //请求数据
    getList(typeName) {
      this.loading = true;
      this.axios
        .get(
          "/api/common_activity_list/search",
          {
            params: {
              date: "",
              role: "admin",
              typeName,
            },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.list = res.data.data;
            this.loading = false;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
          this.loading = false;
        });
    },

    //保存数据
    saveData() {
      //先做表单验证
      if (this.formData.activityName == "") {
        this.$message({
          type: "warning",
          message: "请输入活动名称",
        });
        return;
      }

      if (this.formData.typeId == "") {
        this.$message({
          type: "warning",
          message: "请选择活动类型",
        });
        return;
      }

      if (this.formData.startTime == "") {
        this.$message({
          type: "warning",
          message: "请选择活动开始结束日期",
        });
        return;
      }

      if (this.formData.endTime == "") {
        this.$message({
          type: "warning",
          message: "请选择活动开始结束日期",
        });
        return;
      }

      if (this.formData.signUpStartTime == "") {
        this.$message({
          type: "warning",
          message: "请选择报名开始结束日期",
        });
        return;
      }

      if (this.formData.signUpEndTime == "") {
        this.$message({
          type: "warning",
          message: "请选择报名开始结束日期",
        });
        return;
      }

      if (this.formData.classesArea.length == 0) {
        this.$message({
          type: "warning",
          message: "必须选择至少一个班级",
        });
        return;
      }

      // 提交保存新建信息接口
      this.$confirm("确认保存该信息吗?", "提交确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.createActivity(this.formData);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //请求提交更新活动列表接口
    createActivity(formData) {
      this.axios
        .post(
          "/api/web_activity_list/update",
          {
            formData,
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "数据保存成功!",
            });
            this.getList();
            this.showEditWindow = false;
          } else {
            this.$message.error("数据提交失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    //跳转日程表
    manageRow(index, row) {
      this.$router.push({ path: "/timeTable/" + row.activityId });
    },

    //执行搜索
    doSeatch() {
      this.getList(this.searchName);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.main-btn {
  margin: 20px;
  position: relative;
}

.createBtn {
  margin: 20px;
}

.main-form-div {
  margin: 20px;
}

.input-search {
  width: 250px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.input-search div {
  display: inline-block;
}

.dialogWindow p {
  color: #909399;
}
</style>