<template>
  <div class="main">
    <!-- 按钮组 -->
    <div class="main-btn">
      <el-button
        size="medium"
        type="primary"
        class="createBtn"
        @click="createNew()"
        >新建</el-button
      >

      <el-button size="medium" class="createBtn" @click="download()"
        >报表导出</el-button
      >

      <el-button size="medium" class="createBtn" @click="downloadTemplate()"
        >模板下载</el-button
      >

      <el-upload
        action="/api/timeTable_template/upload"
        :data="{ formName: this.value, activityId:this.formData.activityId }"
        :limit="999"
        :show-file-list="false"
        :on-success="importData"
      >
        <el-button class="createBtn" size="medium">批量导入</el-button>
      </el-upload>
    </div>

    <!-- 表格部分 -->
    <div class="main-form-div">
      <el-table
        border
        v-loading="loading"
        :data="
          this.list.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" width="55" label="序号"></el-table-column>
        <el-table-column prop="date" label="日期"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
        <el-table-column
          prop="subjectGroupName"
          label="分组名称"
        ></el-table-column>
        <el-table-column
          prop="allJoinedNum"
          label="已报名人数"
        ></el-table-column>
        <el-table-column
          prop="allprojectItemNum"
          label="总时段数"
        ></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click.prevent="editRow(scope.$index, scope.row, list)"
              type="text"
              size="small"
              >编辑</el-button
            >

            <el-button
              @click.prevent="deleteRow(scope.$index, scope.row, list)"
              type="text"
              size="small"
              style="color: red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin: 20px"
        background
        layout="total, prev, pager, next, sizes"
        @current-change="handleCurrentChange"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <!-- 模态弹出框部分 -->
    <!-- 新建/编辑窗口 -->
    <el-dialog :title="dialogTitle" :visible.sync="showEditWindow" width="40%">
      <el-form ref="formData" label-width="150px" class="dialogWindow">
        <el-form-item label="日期">
          <el-date-picker
            v-model="formData.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="起始时间">
          <el-time-select
            placeholder="起始时间"
            v-model="formData.startTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '20:30',
            }"
          >
          </el-time-select>
        </el-form-item>

        <el-form-item label="结束时间">
          <el-time-select
            placeholder="结束时间"
            v-model="formData.endTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '20:30',
              minTime: formData.startTime,
            }"
          >
          </el-time-select>
        </el-form-item>

        <el-form-item label="间隔时间(分钟)">
          <el-input-number
            v-model="formData.timeStep"
            :min="1"
            :max="60"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="持续时间(分钟)">
          <el-input-number
            v-model="formData.duration"
            :min="1"
            :max="600"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="学科分组">
          <el-select
            v-model="formData.subjectGroupId"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in subjectGroupOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditWindow = false">取 消</el-button>
        <el-button type="primary" @click="showNext()">下一步</el-button>
      </span>
    </el-dialog>

    <!-- 第二步窗口 -->
    <el-dialog title="详情编辑" :visible.sync="showStep2Window" width="80%">
      <el-form ref="formData" label-width="150px" class="dialogWindow">
        <el-table :data="formData.arrangementList" style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline>
                <div v-for="(item, index) in props.row.staffList" :key="index">
                  <el-form-item :label="index + 1 + ''">
                    <div>
                      学科:
                      <el-select
                        v-model="item.subjectId"
                        filterable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in subjectOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <div>
                      人员:
                      <el-cascader
                        v-model="item.staffId"
                        :options="staffOption"
                        filterable
                        clearable
                        :show-all-levels='false'
                      ></el-cascader>
                    </div>
                  </el-form-item>

                  <el-button
                    @click.prevent="
                      deleteSubjectRow(
                        index,
                        formData.arrangementList,
                        props.$index
                      )
                    "
                    type="text"
                    size="small"
                    style="color: red"
                    >删除学科</el-button
                  >
                </div>
              </el-form>
            </template>
          </el-table-column>

          <el-table-column label="班级">
            <template slot-scope="scope">
              <el-cascader
                v-model="scope.row.classId"
                :options="classOption"
                :props="{ multiple: true }"
                filterable
                clearable
              ></el-cascader>
            </template>
          </el-table-column>

          <el-table-column label="限制">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.limit"
                :min="1"
                :max="500"
              ></el-input-number>
            </template>
          </el-table-column>

          <el-table-column label="场地">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.placeId"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in placeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click.prevent="
                  addClassRow(scope.$index, scope.row, formData.arrangementList)
                "
                type="text"
                size="small"
                >新增班级</el-button
              >

              <el-button
                @click.prevent="
                  addSubjectRow(
                    scope.$index,
                    scope.row,
                    formData.arrangementList
                  )
                "
                type="text"
                size="small"
                >新增学科</el-button
              >

              <el-button
                @click.prevent="
                  deleteClassRow(
                    scope.$index,
                    scope.row,
                    formData.arrangementList
                  )
                "
                type="text"
                size="small"
                style="color: red"
                >删除班级</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="previous()">上一步</el-button>
        <el-button type="primary" @click="saveData()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "activityForm",
  data() {
    return {
      searchName: "",
      loading: false,
      currentPage: 1 /* 当前页页码 */,
      pageSize: 10 /* 每页显示的条数 */,
      list: [],
      //   新建表单属性
      formData: {
        activityId: this.$route.params.id,
        timeTableId: "",
        date: "",
        timeStep: 1,
        duration: 1,
        startTime: "",
        endTime: "",
        subjectGroupId: "",
        arrangementList: [
          {
            classId: [],
            limit: 1,
            placeId: [],
            staffList: [
              {
                subjectId: "",
                staffId: [],
              },
            ],
          },
        ],
      },

      //   弹出框属性
      showEditWindow: false,
      showStep2Window: false,
      dialogTitle: "新建",

      //选项的属性
      subjectGroupOption: [],
      placeOption: [],
      classOption: [],
      subjectOption: [],
      staffOption: [],
    };
  },

  mounted() {
    //请求数据
    this.getList(this.$route.params.id);
    this.getSubjectGroupOption(this.$route.params.id);
  },

  methods: {
    // 页码器的函数
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },

    //弹出窗逻辑
    //创建按钮
    createNew() {
      this.showEditWindow = true;
      this.dialogTitle = "新建";
      this.formData.timeTableId = "";
      this.formData.date = "";
      this.formData.timeStep = 1;
      this.formData.duration = 1;
      this.formData.startTime = "";
      this.formData.endTime = "";
      this.formData.subjectGroupId = "";
      this.formData.arrangementList = [
        {
          classId: "",
          limit: 1,
          placeId: [],
          staffList: [
            {
              subjectId: "",
              staffId: [],
            },
          ],
        },
      ];
    },

    //编辑按钮
    editRow(index, row) {
      this.showEditWindow = true;
      this.dialogTitle = "编辑";
      this.formData.timeTableId = row.timeTableId;
      this.formData.date = row.date;
      this.formData.timeStep = row.timeStep;
      this.formData.duration = row.duration;
      this.formData.startTime = row.startTime;
      this.formData.endTime = row.endTime;
      this.formData.subjectGroupId = row.subjectGroupId;
      this.formData.arrangementList = row.arrangementList;
    },

    // 删除逻辑
    deleteRow(index, row, table) {
      this.$prompt("请输入Delete删除这条信息", "删除确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (value === "Delete") {
          this.$confirm(
            "确认删除该信息吗?删除后该信息后的所有记录将被清除！",
            "删除确认",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              let deleteData = [];
              deleteData.push(row.timeTableId);

              //请求删除的接口
              this.axios
                .post(
                  "/api/web_time_table/delete",
                  {
                    ids: deleteData,
                  },
                  { headers: { token: this.$store.state.token } }
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });

                    if (
                      table[1 + (this.currentPage - 1) * this.pageSize] == null
                    ) {
                      //如果当前页是最后一页，并且倒数第二项是空的，说明删除的是最后一页的最后一项
                      table.splice(
                        index + (this.currentPage - 1) * this.pageSize,
                        1
                      );
                      this.setCurrentPage();
                    } else {
                      table.splice(
                        index + (this.currentPage - 1) * this.pageSize,
                        1
                      );
                    }
                  } else {
                    this.$message.error("数据删除失败：" + res.data.message);
                  }
                })
                .catch((err) => {
                  this.$message.error("服务错误：" + err.response.status);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "info",
            message: "输入错误，取消删除操作！",
          });
        }
      });
    },

    setCurrentPage() {
      this.currentPage = this.currentPage - 1;
      console.log(this.currentPage);
    },

    //请求数据
    getList(activityId) {
      this.loading = true;
      this.axios
        .get(
          "/api/common_time_table/search",
          {
            params: {
              activityId,
              role: "admin",
            },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.list = res.data.data;
            this.loading = false;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
          this.loading = false;
        });
    },

    //
    showNext() {
      this.showEditWindow = false;
      this.showStep2Window = true;
      this.getPlaceOption();
      this.getStaffOption();
      this.getClassOption(this.$route.params.id);
      this.getSubjectOption(this.formData.subjectGroupId);
    },

    previous() {
      this.showEditWindow = true;
      this.showStep2Window = false;
    },

    //保存数据
    saveData() {
      //先做表单验证
      if (this.formData.date == "") {
        this.$message({
          type: "warning",
          message: "请输入日期",
        });
        return;
      }

      if (this.formData.startTime == "") {
        this.$message({
          type: "warning",
          message: "请输入开始时间",
        });
        return;
      }

      if (this.formData.endTime == "") {
        this.$message({
          type: "warning",
          message: "请输入结束时间",
        });
        return;
      }

      if (this.formData.subjectGroupId == "") {
        this.$message({
          type: "warning",
          message: "请输入分组名字",
        });
        return;
      }

      // 提交保存新建信息接口
      this.$confirm("确认保存该信息吗?", "提交确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.createTimeTable(this.formData);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //请求提交更新活动列表接口
    createTimeTable(formData) {
      this.axios
        .post(
          "/api/web_time_table/update",
          {
            formData,
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "数据保存成功!",
            });
            this.getList(this.$route.params.id);
            this.showEditWindow = false;
          } else {
            this.$message.error("数据提交失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    //执行搜索
    doSeatch() {
      this.getList(this.$route.params.id);
    },

    addClassRow(index, row, table) {
      let newRow = {
        classId: [],
        limit: 1,
        placeId: [],
        staffList: [
          {
            subjectId: "",
            staffId: [],
          },
        ],
      };
      table.push(newRow);
    },

    addSubjectRow(index, row, table) {
      let newRow = {
        subjectId: "",
        staffId: [],
      };
      table[index].staffList.push(newRow);
    },

    deleteClassRow(index, row, table) {
      if (table.length > 1) {
        table.splice(index, 1);
      } else {
        this.$message({
          type: "warning",
          message: "不能删除最后一行数据",
        });
      }
    },

    deleteSubjectRow(index, table, tableIndex) {
      if (table[tableIndex].staffList.length > 1) {
        table[tableIndex].staffList.splice(index, 1);
      } else {
        this.$message({
          type: "warning",
          message: "不能删除最后一行数据",
        });
      }
    },

    //获取各类选项
    getSubjectGroupOption(activityId) {
      this.axios
        .get(
          "/api/web_subject_group_option/search",
          {
            params: {
              activityId,
            },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.subjectGroupOption = res.data.data;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    getPlaceOption() {
      this.axios
        .get(
          "/api/common_place_list/search",
          {
            params: {},
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.placeOption = res.data.data;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    getClassOption(activityId) {
      this.axios
        .get(
          "/api/class_area_list/search",
          {
            params: { activityId },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.classOption = res.data.data;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    getSubjectOption(subjectGroupId) {
      this.axios
        .get(
          "/api/web_subject_option/search",
          {
            params: { subjectGroupId },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.subjectOption = res.data.data;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    getStaffOption() {
      this.axios
        .get(
          "/api/common_staff_list/search",
          {
            params: {},
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.staffOption = res.data.data;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    //导出信息
    download() {
      this.axios
        .get(
          "/api/activity_report/search",
          {
            params: {
              activityId: this.$route.params.id,
            },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            window.open(res.data.data.url);
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    downloadTemplate() {
      this.axios
        .get(
          "/api/timeTable_template/search",
          {
            params: {
              activityId: this.$route.params.id,
            },
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            window.open(res.data.data.url);
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
        });
    },

    importData(res, file, filelist) {
      //导入数据
      this.$message({
        type: "success",
        message: "上传成功",
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.main-btn {
  margin: 20px;
  position: relative;
}

.createBtn {
  margin: 20px;
}

.main-form-div {
  margin: 20px;
}

.input-search {
  width: 250px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.input-search div {
  display: inline-block;
}

.dialogWindow p {
  color: #909399;
}
</style>