 <template>
  <div class="main">
    <main-header></main-header>
    <main-menu
      :menuNameList="menuNameList"
      @selectMenu="selectMenu"
    ></main-menu>

    <div class="main-body">
      <div class="main-body-window">
        <!-- 在这里进行动态组件注册 -->
        <components :is="activeComponent"></components>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/pages/common/header.vue";
import mainMenu from "@/pages/common/menu.vue";
import timeTableList from "@/pages/time_table/time_table_list/time_table_list.vue";
import subjectSetting from "@/pages/time_table/subject_setting/subject_setting.vue";

export default {
  name: "loginPage",

  components: {
    mainHeader,
    mainMenu,
    timeTableList,
    subjectSetting
  },

  mounted() {
    this.getList(this.$route.params.id);
  },

  data() {
    return {
      menuNameList: [
        {
          index: "timeTableList",
          disabled: false,
          title: "日程表",
        },
        {
          index: "subjectSetting",
          disabled: false,
          title: "学科设置",
        },
      ], //子菜单的列表名字

      activeComponent: "",
    };
  },

  methods: {
    selectMenu(key) {
      this.activeComponent = key;
    },

    getList(id) {
      console.log(id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

</style>
