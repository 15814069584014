import Vue from 'vue'
import Router from 'vue-router'
import cookie from '@/assets/js/cookie'
import loginPage from '../pages/login.vue'
import homePage from '../pages/home_page/home_page.vue'
import timeTable from '../pages/time_table/time_table.vue'



Vue.use(Router)// 在这里做路由守卫，拒绝未登录的链接请求


const router = new Router({

  routes: [
    {
      path: '/',
      name: 'loginPage',
      component: loginPage,
    },
    {
      path: '/homePage',
      name: 'homePage',
      component: homePage,
      meta: {
        requireAuthStaff: true //待修改
      }
    },  
    {
      path: '/timeTable/:id',
      name: 'timeTable',
      component: timeTable,
      meta: {
        requireAuthStaff: true //待修改
      }
    }
  ],

})

// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {
  const token = cookie.getCookie("token") // 改为由cookie是否存在来判断是否拦截
  if (to.meta.requireAuthStaff) { // 判断该路由是否需要登录权限
    if (token) {
      next()
    } else {
      alert('请先登录腾讯智慧校园！')
      location.href = "https://sso.qq.com/statics/login-pc/index.html?app=10000#/"
    }
  }
  else {
    next()
  }
})

/* 最后把router当做模块映射出去 */
export default router
